/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Burger } from '@sicpama/core-components';
import { Input } from 'antd';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventListener from 'src/components/EventListener/EventListener';
import SideNavigation from 'src/components/SideNavigation/SideNavigation';
import { COUNTRY_CODES } from 'src/constants';
import { useAudio } from 'src/hooks/useAudio';
import {
  KDS_EVENT,
  OrderItemStatus,
  OrderStatus,
  STORE_ATTRIBUTE_NAME,
} from 'src/submodules/sicpama-shared';

import Select from '../../components/System/Select';
import Waypoint from '../../components/WayPoint/WayPoint';
import { getToken } from '../../services/storage.service';
import { useOrderState, useStoreState } from '../../States';
import { httpSearchOrders } from '../../States/Order/repository/orders.http';
import { searchOrders } from '../../States/Order/useCases/searchOrders';

import CloseBusiness from './CloseBusiness';
import CompletedOrderCard from './CompletedOrderCard';
import FireWorkAnimation from './fireworkAnimation.json';
import InProgressOrderCard from './InProgressOrderCard';
import PauseBusiness from './PauseBusiness';

const FoodTruck = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [tab, setTab] = useState('in-progress');
  const [loading, setLoading] = useState(false);

  const [canLoadMore, setCanLoadMore] = useState(false);
  const { id: storeId, attributes, countryCode } = useStoreState((state) => state.storeData);
  const { storeFingerPrint } = useStoreState();
  const searchedOrders = useOrderState((state) => state.searchOrders);
  const [displayNavbar, setDisplayNavbar] = useState(false);

  const [animationState, setAnimationState] = useState(false);

  const { playAudio } = useAudio('https://cdn.sicpama.com/sicpama-assets/new-order.mp3');

  const shouldCountNumberOfSentPickUpEnabled = useMemo(() => {
    const countNumberOfSentPickUpEnabledAttr = attributes?.find(
      (attr) => attr.name === STORE_ATTRIBUTE_NAME.COUNT_NUMBER_OF_SENT_PICK_UP_ENABLED,
    );
    return countNumberOfSentPickUpEnabledAttr?.value === 'true';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  const [animating, setAnimating] = useState(false);

  const handleOrderStateAnimation = (tabId = 'completed-tab') => {
    if (animating) return;
    const completeButton = document.getElementById('change-order-state-button');
    const buttonRect = completeButton.getBoundingClientRect();
    const completedTab = document.getElementById(tabId);
    const completedTabRect = completedTab.getBoundingClientRect();

    setAnimating(true);

    const ball = document.createElement('div');
    ball.style.position = 'fixed';
    ball.style.left = `${buttonRect.left + buttonRect.width / 2 - 30}px`;
    ball.style.top = `${buttonRect.top}px`;
    ball.style.width = '60px';
    ball.style.height = '60px';
    ball.style.backgroundColor = '#3b82f6';
    ball.style.borderRadius = '50%';
    ball.style.transition = 'all 1s ease-in-out';
    ball.style.zIndex = '9999';

    document.body.appendChild(ball);

    requestAnimationFrame(() => {
      const dx = completedTabRect.left - buttonRect.left + (completedTabRect.width / 2 - 30);
      const dy =
        completedTabRect.top - buttonRect.top - buttonRect.height / 2 + completedTabRect.height / 2;
      ball.style.transform = `translate(${dx}px, ${dy}px) scale(0.1)`;
    });

    setTimeout(() => {
      document.body.removeChild(ball);
      setAnimating(false);
    }, 1000);
  };

  const loadOrders = async (loadMore: boolean = false) => {
    setLoading(true);
    const result = await searchOrders(
      getToken,
      httpSearchOrders,
      shouldCountNumberOfSentPickUpEnabled,
      dayjs().format('YYYY-MM-DD'),
      500,
    )('', loadMore);
    setCanLoadMore(result);
    setLoading(false);
  };

  const allOrders = useMemo(() => {
    const filteredByStatuses = Object.values(searchedOrders).filter((item) =>
      [OrderStatus.PRINTED, OrderStatus.PAID].includes(item.orderStatus),
    );

    if (searchValue) {
      return filteredByStatuses.filter((item) => item.phoneNumber.endsWith(searchValue));
    }
    return filteredByStatuses;
  }, [searchedOrders, searchValue]);

  const inProgressOrders = useMemo(() => {
    return allOrders.filter((item) => item.items.some((i) => i.status !== OrderItemStatus.SERVED));
  }, [allOrders]);

  const completedOrders = useMemo(() => {
    return allOrders
      .filter((item) => item.items.every((i) => i.status === OrderItemStatus.SERVED))
      .sort((a, b) => {
        const aLatestUpdate = Math.max(
          ...a.items.map((item) => new Date(item.updatedAt).getTime()),
        );
        const bLatestUpdate = Math.max(
          ...b.items.map((item) => new Date(item.updatedAt).getTime()),
        );
        return bLatestUpdate - aLatestUpdate; // Descending order
      });
  }, [allOrders]);

  const loadMore = useCallback(async () => {
    if (canLoadMore === false) {
      return;
    }
    void loadOrders(true);
  }, [canLoadMore]);

  useEffect(() => {
    loadOrders();
  }, [tab, storeId]);

  const firstDivRef = useRef(null);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsFirstDivVisible(entry.isIntersecting);
    }, options);

    if (firstDivRef.current) {
      observer.observe(firstDivRef.current);
    }

    return () => {
      if (firstDivRef.current) {
        observer.unobserve(firstDivRef.current);
      }
    };
  }, []);

  const key = (event: KDS_EVENT) => `${storeId}_${event}-event`;
  const channelName = () => `KDS_${storeId?.toString()}`;

  const playAnimation = () => {
    setAnimationState(true);
    setTimeout(() => {
      setAnimationState(false);
    }, 5000);
  };

  return (
    <>
      <div ref={firstDivRef} className="p-4 flex items-center w-screen justify-between">
        <div
          onClick={() => {
            setDisplayNavbar((prev: boolean) => !prev);
          }}
          className="w-20"
        >
          <Burger
            opened={displayNavbar}
            onClick={() => {
              // setDisplayNavbar((prev: boolean) => !prev);
            }}
          />
        </div>
        <div className="flex gap-4">
          <PauseBusiness />
          <CloseBusiness />
        </div>
        <img
          className="w-[35px] h-[28px]"
          src={
            countryCode === COUNTRY_CODES.KR
              ? `${process.env.PUBLIC_URL}/assets/images/SendEat.svg`
              : 'https://www.raptorpos.com/images/favicon.png'
          }
          alt="SendEatLogo"
        />
      </div>
      <div
        className={`grid grid-cols-2 mx-4 ${
          !isFirstDivVisible
            ? 'fixed top-0 left-0 right-0 bg-white z-10 h-14 border-b-4 border-gray-500'
            : 'h-7 '
        }`}
      >
        <div
          id="in-progress-tab"
          onClick={() => setTab('in-progress')}
          className={`flex items-center justify-center border pr-2 rounded-t-[8px] text-[20px] leading-[26px] h-[48px] truncate ${
            tab === 'in-progress'
              ? 'bg-[#F2F2F7] border-b-gray-100 border-t-black border-x-black text-[#004FA4]'
              : 'bg-[#D9D9D9] border-b-black border-t-gray-100 border-r-0 text-[#8E8E8E]'
          }`}
        >
          {animationState && <Lottie loop={animationState} animationData={FireWorkAnimation} />}
          <span
            className={`text-white text-[20px] leading-[21px] px-0.5 rounded-[8px] mr-1 ${
              tab === 'in-progress' ? 'bg-[#FC5C0C]' : 'bg-[#8E8E8E]'
            }`}
          >
            {inProgressOrders.length > 99 ? '99+' : inProgressOrders.length}
          </span>
          {t('food-truck.in-progress')}
        </div>
        <div
          id="completed-tab"
          onClick={() => setTab('completed')}
          className={`flex items-center justify-center border px-2 rounded-t-[8px] text-[20px] leading-[26px] truncate ${
            tab === 'completed'
              ? 'bg-[#F2F2F7] border-b-gray-100 border-t-black border-x-black text-[#004FA4]'
              : 'bg-[#D9D9D9] border-b-black border-t-gray-100 border-r-0 text-[#8E8E8E]'
          }`}
        >
          <span
            className={`text-white text-[20px] leading-[21px] px-0.5 rounded-[8px] mr-1 ${
              tab === 'completed' ? 'bg-[#FC5C0C]' : 'bg-[#8E8E8E]'
            }`}
          >
            {completedOrders.length > 99 ? '99+' : completedOrders.length}
          </span>
          {t('food-truck.completed')}
        </div>
      </div>
      <div className="flex w-screen items-center gap-4 px-4 bg-[#F2F2F7] py-4 mt-4">
        <Select
          items={[{ value: 1, label: `${t('food-truck.mobilePhone')}` }]}
          onSelect={() => {}}
          value={1}
          className="h-9 !w-36 [&_[class*='select-button']]:border-0 [&_[class*='select-button']]:focus:!ring-0 [&_[class*='select-button']]:rounded-[100px] text-sm"
        />
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="[&_>_input]:placeholder:text-center rounded-[100px] h-9"
          placeholder={t('food-truck.last4Digit')}
          prefix={<SearchOutlined className="text-[#D9D9D9]" />}
          suffix={
            <CloseOutlined
              className="text-[#D9D9D9]"
              onClick={() => {
                setSearchValue('');
              }}
            />
          }
          maxLength={4}
          type="tel"
        />
      </div>

      {tab === 'in-progress' && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 px-4 py-2 bg-[#F2F2F7]">
            {inProgressOrders.map((item) => (
              <InProgressOrderCard
                item={item}
                key={item.orderId}
                loadOrders={loadOrders}
                startAnimation={handleOrderStateAnimation}
              />
            ))}
          </div>
          <Waypoint onEnter={loadMore} />
        </>
      )}
      {tab === 'completed' && (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 px-4 py-2 bg-[#F2F2F7]">
          {completedOrders.map((item) => (
            <CompletedOrderCard
              item={item}
              key={item.orderId}
              loadOrders={loadOrders}
              startAnimation={handleOrderStateAnimation}
            />
          ))}
        </div>
      )}

      <SideNavigation
        placement="left"
        open={displayNavbar}
        onClose={() => setDisplayNavbar(false)}
      />
      <div>
        <EventListener
          key={key(KDS_EVENT.TABLE_PAID)}
          channelName={channelName()}
          msgKey={KDS_EVENT[KDS_EVENT.TABLE_PAID]}
          callBackFun={() => {
            loadOrders(canLoadMore);
            playAudio();
            playAnimation();
          }}
        />
        {/* For the below events, only other devices need to loadOrders again
        The current device already has the updated data */}
        <EventListener
          key={key(KDS_EVENT.BULK_ITEMS_UPDATE)}
          channelName={channelName()}
          msgKey={KDS_EVENT[KDS_EVENT.BULK_ITEMS_UPDATE]}
          callBackFun={(data) => {
            if (data?.data?.fingerPrint !== storeFingerPrint) {
              loadOrders(canLoadMore);
            }
          }}
        />
        <EventListener
          key={key(KDS_EVENT.REFUND_ORDER)}
          channelName={channelName()}
          msgKey={KDS_EVENT[KDS_EVENT.REFUND_ORDER]}
          callBackFun={(data) => {
            if (data?.data?.fingerPrint !== storeFingerPrint) {
              loadOrders(canLoadMore);
            }
          }}
        />

        <EventListener
          key={key(KDS_EVENT.NOTIFY_TO_PICK_UP_ORDER)}
          channelName={channelName()}
          msgKey={KDS_EVENT[KDS_EVENT.NOTIFY_TO_PICK_UP_ORDER]}
          callBackFun={(data) => {
            if (data?.data?.fingerPrint !== storeFingerPrint) {
              loadOrders(canLoadMore);
            }
          }}
        />
      </div>
    </>
  );
};

export default FoodTruck;
