import { notifications } from '@sicpama/core-components/lib/notifications';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { KDS_HTTP_HEADERS } from '../submodules/sicpama-shared';

import { loginService } from './login.service';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  timeout: 30000,
  paramsSerializer: (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_k, v]) => v));
    return queryString.stringify(filteredParams, { arrayFormat: 'bracket' });
  },
});

export default class BaseHttpService {
  addAuthHeader(options: AxiosRequestConfig): AxiosRequestConfig {
    if (loginService.getStaffToken() === null) {
      return options;
    }
    return {
      ...options,
      headers: {
        Authorization: 'Bearer ' + loginService.getStaffToken(),
        [KDS_HTTP_HEADERS.STORE_FINGERPRINT]:
          JSON.parse(localStorage.getItem('store-storage') || '{}')?.state?.fingerPrint || '',
      },
    };
  }

  async get<T>(endpoint: string, options = {}): Promise<AxiosResponse<T>> {
    return instance
      .get<T>(`/${endpoint}`, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async post<T>(endpoint: string, data = {}, options = {}): Promise<AxiosResponse<T>> {
    return instance
      .post<T>(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async postWithoutToken<T, D>(endpoint: string, data: D, options = {}): Promise<AxiosResponse<T>> {
    return instance
      .post(`/${endpoint}`, data, options)
      .catch((error) => this.handleHttpError(error));
  }

  async put(endpoint: string, data = {}, options = {}): Promise<AxiosResponse> {
    return instance
      .put(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async delete(endpoint: string, options = {}): Promise<AxiosResponse> {
    return instance
      .delete(`/${endpoint}`, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  async patch(endpoint: string, data = {}, options = {}): Promise<AxiosResponse> {
    return instance
      .patch(`/${endpoint}`, data, this.addAuthHeader(options))
      .catch((error) => this.handleHttpError(error));
  }

  /**
   * Called whenever an HTTP request fails.
   * You can show notifications, log to console, or redirect.
   * IMPORTANT: Re-throw the error so the caller's promise rejects.
   */
  handleHttpError(error: any): never {
    console.error('HTTP request failed:', error);
    const { language } = navigator;
    // If there's no .data, turn it into a typed error
    if (axios.isAxiosError(error) && !error?.response?.data) {
      error = {
        ...error,
        statusCode: error?.response?.status,
        message: error.message,
      };
    }

    const errorMessage = error?.response?.data?.errorMessage || 'Error';
    notifications.show({
      title: errorMessage,
      message: language.startsWith('ko')
        ? '불편을 끼쳐드려 죄송합니다!'
        : 'Sorry For This Inconvenience!',
      radius: 'md',
      color: 'red',
      autoClose: process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
        ? +process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
        : 5000,
    });

    // Re-throw so that "await someHttpCall" will reject & can be caught by caller
    throw error;
  }
}
