import { configureAbly } from '@ably-labs/react-hooks';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './styles/global.scss';
import './i18n';

import { AuthProvider } from './AuthContext';
import LoginForm from './components/Login/Login';
import { ModalProvider } from './components/Modal/Modal';
import ErrorBoundary from './ErrorBoundary';
import { ROUTES } from './helpers/constants';
import MainLayout from './layouts/MainLayout';
import CrmDemoPage from './pages/crm/CrmDemoPage';
import FoodTruck from './pages/food-truck';
import SuccessPage from './pages/management-page/payments/SuccessPage';

const DataReport = lazy(() => import('./pages/data-report'));
const InventoryList = lazy(
  () => import('./pages/inventory-management/InventoryList/InventoryList'),
);

const StoreInfo = lazy(() => import('./pages/management-page/store-info-2'));
const MenuManagement = lazy(() => import('./pages/menu-management/MenuManagement'));
const MerchantSignUp = lazy(() => import('./pages/merchant-signup'));
const SaleStatus = lazy(() => import('./pages/sale-status/SaleStatus'));
const SearchOrders = lazy(() => import('./pages/search-orders/SearchOrders'));
const TableStatus = lazy(() => import('./pages/table-status/TableStatus'));
const TransactionHistory = lazy(() => import('./pages/transaction-history/TransactionHistory'));

configureAbly({
  authUrl: `${process.env.REACT_APP_API_URL}/v1/real-time-auth/token-request`,
});

// Register the chart elements to be used in the app
Chart.register(ArcElement, Tooltip, Legend);

const gtmParams: ISnippetsParams = {
  id: process.env.REACT_APP_GA_TAG_MANAGER_ID!,
};

function App(): JSX.Element {
  return (
    <GTMProvider state={gtmParams}>
      <BrowserRouter>
        <ErrorBoundary>
          <ModalProvider>
            <AuthProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path={ROUTES.LOGIN}>
                    <Route path={ROUTES.MERCHANT_SIGNUP} element={<MerchantSignUp />} />
                    <Route index element={<LoginForm />} />
                  </Route>
                  <Route path={'/app/food-truck'} element={<FoodTruck />} />
                  <Route path={'/app/management/store-info'} element={<StoreInfo />} />
                  <Route path={'/app/management/payments/success'} element={<SuccessPage />} />
                  <Route path="/app" element={<MainLayout />}>
                    <Route path={'table-status'} element={<TableStatus />}></Route>
                    <Route path={'table-status/search'} element={<SearchOrders />}></Route>
                    <Route path={'sale-status'} element={<SaleStatus />}></Route>
                    <Route path={'transaction-history'} element={<TransactionHistory />}></Route>
                    <Route path={'management/menu-management'} element={<MenuManagement />} />
                    <Route path={'management/inventory-management'} element={<InventoryList />} />
                    <Route path={'data-report'} element={<DataReport />} />
                    <Route path={'crm'} element={<CrmDemoPage />} />
                    <Route index element={<Navigate to={ROUTES.TABLE_STATUS} />}></Route>
                  </Route>

                  <Route path="/" element={<Navigate to="/app" replace />} />
                  <Route path="*" element={<Navigate to="/app" replace />} />
                </Routes>
              </Suspense>
            </AuthProvider>
          </ModalProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </GTMProvider>
  );
}

export default App;
